
import { defineAsyncComponent, ref, defineComponent } from 'vue';
import { getLatestTemplateListByPrivacyPolicy, GetLatestTemplateListByPrivacyPolicy,GetSignedPolicyInfoByPrivacyPolicy,getSignedPolicyInfoByPrivacyPolicy } from '@/api/common/privacyPolicy';
import { getPrivacyPolicyLang } from '../utils/utils';

export default defineComponent({
    setup() {
        const componentData = ref({
            fileName: 'property-privacy-policy',

            language: getPrivacyPolicyLang(),
            version: '1',
            uuid: ''
        });
        const formData = ref<GetSignedPolicyInfoByPrivacyPolicy>({
            ID: '',
            UUID: '',
            UserName: '',
            ProviderInfo: {
                ID: '',
                UUID: '',
                CompanyName: '',
                CompanyAddress: '',
                Phone: '',
                Email: '',
                TaxId: '',
                WebSite: '',
                PrivacyPolicyVersion: '',
                UserPolicyVersion: '',
                Language: '',
                UpdateTime: '',
                CreateTime: ''
            },
            SignTime: ''
        });
        // 最新协议模板
        const policyComponent = ref();
        // 获取所有最新的协议模板
        function getLatestTemplate() {
            getLatestTemplateListByPrivacyPolicy({}, (res: any) => {
                res.forEach((item: any) => {
                    if (item.Language === getPrivacyPolicyLang() && item.Type === '0') {
                        componentData.value.version = item.Version;
                    }
                });
                getSignedPolicyInfoByPrivacyPolicy({
                    Language: componentData.value.language
                }, (result: any) => {
                    if (result.length) {
                        formData.value = result;
                        if (formData.value.ProviderInfo) {
                            formData.value.ProviderInfo.CreateTime = result.ProviderInfo ? result.ProviderInfo.CreateTime.split(' ')[0] : '';
                            formData.value.SignTime = result.SignTime ? result.SignTime.split(' ')[0] : '';
                        }
                    }
                    policyComponent.value = defineAsyncComponent(() => import(`./components/${componentData.value.fileName }/${componentData.value.language }/${componentData.value.version }.vue`));
                })
            });

        }
        getLatestTemplate();
        return {
            componentData,
            getLatestTemplate,
            policyComponent,
            formData
        };
    }
});

